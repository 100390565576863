import React from "react";
import countryData from "country-telephone-data";
import { Country, Props as ReactPhoneInputProps } from "react-telephone-input";
import { StyledPhoneInput } from "./PhoneInput.styles";
import flags from "../../../../assets/flags.png";
import { parsePhoneNumber, isValidPhoneNumber } from "libphonenumber-js";
import { SiteSettings } from "common/utils/holvikaari";
import { injectIntl, IntlShape } from "react-intl";

type Props = {
  id?: string;
  className?: string;
  updateCountry?: (value: string) => void;
  onChange: (value: string, country: Country) => void;
  disabled?: boolean;
  defaultCountry?: string;
  value?: string;
  "aria-invalid"?: boolean;
};

type PhoneInputProps = Props &
  Pick<Partial<ReactPhoneInputProps>, "inputProps">;

const { allCountries } = countryData;
const onlyCountries = allCountries.filter(country => country.iso2 !== "ax");

function PhoneInput(props: PhoneInputProps & { intl: IntlShape }) {
  const { updateCountry, onChange, disabled, id } = props;

  React.useEffect(() => {
    if (
      typeof props.value !== "undefined" &&
      props.value.length > 5 &&
      isValidPhoneNumber(props.value)
    ) {
      const phoneNumber = parsePhoneNumber(props.value).formatInternational();
      const countryCode = phoneNumber.substring(1, phoneNumber.indexOf(" "));
      updateCountry?.(countryCode);
    }
  }, [props.value]);

  const performOnChange = (val: string, country: Country) => {
    onChange(val.replace(/[^\d+]/g, ""), country);
    updateCountry?.(country.dialCode);
  };

  const buttonLabel = props.intl.formatMessage({ id: "people.country_code" });
  return (
    <StyledPhoneInput
      {...props}
      buttonProps={{
        "aria-label": buttonLabel,
        disabled,
      }}
      inputProps={{
        disabled,
        id,
        "aria-invalid": props["aria-invalid"],
        maxLength: 20,
        ...props.inputProps,
      }}
      preferredCountries={SiteSettings.phone_countries}
      flagsImagePath={flags}
      onChange={performOnChange}
      onlyCountries={onlyCountries}
    />
  );
}

export default injectIntl(PhoneInput) as React.FC<PhoneInputProps>;
