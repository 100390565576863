import React, { ComponentType, useEffect } from "react";
import styled from "styled-components";
import Bugsnag from "bugsnag";
import {
  withErrorBoundary as _withErrorBoundary,
  useErrorBoundary,
  ErrorBoundary as OriginalErrorBoundary,
} from "react-error-boundary";
import { Button, Icon } from "@netmedi/frontend-design-system";
import {
  colors,
  mediaQueries,
} from "@netmedi/frontend-design-system/dist/styles/variables";
import { mediaMin } from "@netmedi/frontend-design-system/dist/styles/mixins/mediaQueryMixins";
import { FormattedMessage } from "react-intl";

// re-export so components don't have to import error stuff from two places
export { useErrorBoundary } from "react-error-boundary";

const Container = styled.div`
  padding: 2rem 1rem;
  margin: 2rem 1rem;
  text-align: center;
  background-color: ${colors.gray50};
  border: 1px solid ${colors.gray300};
  max-width: 600px;
  ${mediaMin(mediaQueries.small)} {
    padding: 2rem 8rem;
    margin: 2rem auto;
  }
`;

type FallbackProps = {
  error: Error;
  resetErrorBoundary: () => void;
};

const ErrorFallback =
  (FallbackWrapper?: React.ComponentType<any>, props?: any) =>
  /* eslint-disable-next-line react/display-name */
  ({ error, resetErrorBoundary }: FallbackProps) => {
    useEffect(() => {
      if (
        process.env.NODE_ENV !== "development" &&
        process.env.NODE_ENV !== "test"
      ) {
        Bugsnag.notify(error, undefined, (_, event) => {
          event.addMetadata("metaData", {
            location: window.location.pathname,
          });
        });
      }
    }, [error]);

    const ErrorInfo = () => (
      <Container>
        <Icon name="exclamation_mark_triangle_48px" size="large" />
        <p>
          <b>
            <FormattedMessage id="error_boundary.title" />
          </b>
        </p>
        <p>
          <FormattedMessage id="error_boundary.there_was_a_problem" />{" "}
          <FormattedMessage id="error_boundary.please_reload" />
        </p>
        <Button onClick={resetErrorBoundary} type="accent" size="small">
          <FormattedMessage id="error_boundary.reload" />
        </Button>
      </Container>
    );

    return FallbackWrapper ? (
      <FallbackWrapper {...props}>
        <ErrorInfo />
      </FallbackWrapper>
    ) : (
      <ErrorInfo />
    );
  };

export const withErrorBoundary = <Props, WrapperProps>(
  component: ComponentType<Props & { children?: React.ReactNode }>,
  fallbackWrapper?: ComponentType<WrapperProps & { children: any }>,
  wrapperProps?: WrapperProps,
) =>
  _withErrorBoundary<Props & { children?: React.ReactNode }>(component, {
    FallbackComponent: ErrorFallback(fallbackWrapper, wrapperProps),
  });

export const ErrorBoundary = ({ children }: { children?: React.ReactNode }) => {
  return (
    <OriginalErrorBoundary FallbackComponent={ErrorFallback()}>
      {children}
    </OriginalErrorBoundary>
  );
};

// component for testing error boundaries
export const ErrorButton = () => {
  const { showBoundary } = useErrorBoundary();
  const onClick = () => {
    showBoundary(new Error("ErrorButton"));
  };

  return (
    <Button style={{ background: "red", color: "white" }} onClick={onClick}>
      Throw error
    </Button>
  );
};
