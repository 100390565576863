import type {
  ClientTreatmentProgram,
  SiteTreatmentProgram,
  TreatmentProgramCategory,
  TaskAction,
  TreatmentProgram,
  Team,
} from "hcp/models/treatmentProgram";
import {
  DeleteTaskAction,
  TreatmentProgramAction,
  UpdateTaskAction,
} from "hcp/actions/treatmentPrograms";
import { RootState } from "store";
import { parseDateString } from "common/utils/general";

const initialState: {
  readonly all: ClientTreatmentProgram[];
  readonly categories: TreatmentProgramCategory[];
  readonly client_programs: ClientTreatmentProgram[];
  readonly all_site_programs: SiteTreatmentProgram[];
  readonly stubs: TreatmentProgram[];
  readonly allTaskActions: TaskAction[];
  readonly treatmentProgram?: ClientTreatmentProgram;
} = {
  all: [],
  categories: [],
  client_programs: [],
  all_site_programs: [],
  stubs: [],
  allTaskActions: [],
  treatmentProgram: undefined,
};

const updateTaskReducer = (
  state: TreatmentProgramsState,
  data: UpdateTaskAction["data"],
) => {
  const { treatmentProgramId, data: timer } = data;
  const dataToReturn = state.client_programs.map(cp => {
    if (cp.id === treatmentProgramId) {
      const changedTasks = cp.tasks?.map(cpt =>
        cpt.id === timer.id ? timer : cpt,
      );
      return { ...cp, tasks: changedTasks };
    }
    return cp;
  });
  return dataToReturn;
};

const deleteTaskReducer = (
  state: TreatmentProgramsState,
  data: DeleteTaskAction["data"],
) => {
  const { tp_id, task_id } = data;
  const dataToReturn = state.client_programs.map(cp => {
    if (cp.id === tp_id) {
      const changedTasks = cp.tasks?.filter(cpt => cpt.id !== task_id);
      return { ...cp, tasks: changedTasks };
    }
    return cp;
  });
  return dataToReturn;
};

const removeTreatmentProgramStaffReducer = (
  state: TreatmentProgramsState,
  staffId: number,
) => {
  if (state.treatmentProgram === undefined) return undefined;
  const treatmentProgram = { ...state.treatmentProgram };
  treatmentProgram.staff = treatmentProgram.staff?.filter(
    item => item.id !== staffId,
  );
  return treatmentProgram;
};

const removeTreatmentProgramTeamReducer = (
  state: TreatmentProgramsState,
  teamId: number,
) => {
  if (state.treatmentProgram === undefined) return undefined;
  const treatmentProgram = { ...state.treatmentProgram };
  treatmentProgram.hcp_teams = treatmentProgram.hcp_teams?.filter(
    item => item.id !== teamId,
  );
  return treatmentProgram;
};

const addTreatmentProgramTeamReducer = (
  state: TreatmentProgramsState,
  data: Team,
) => {
  if (state.treatmentProgram === undefined) return undefined;
  const treatmentProgram = { ...state.treatmentProgram };
  treatmentProgram.hcp_teams = [...(treatmentProgram.hcp_teams ?? []), data];
  return treatmentProgram;
};

const addTreatmentProgramStaffReducer = (
  state: TreatmentProgramsState,
  data: any,
) => {
  if (state.treatmentProgram === undefined) return undefined;
  const treatmentProgram = { ...state.treatmentProgram };
  treatmentProgram.staff = [...(treatmentProgram.staff ?? []), data];
  return treatmentProgram;
};

const parseDates = ({
  begin_date,
  treatment_start_date,
  updated_at,
}: ClientTreatmentProgram) => ({
  begin_date: begin_date ? parseDateString(begin_date) : undefined,
  treatment_start_date: treatment_start_date
    ? parseDateString(treatment_start_date)
    : undefined,
  updated_at: updated_at ? parseDateString(updated_at) : undefined,
});

export type TreatmentProgramsState = typeof initialState;

export default (
  state = initialState,
  action: TreatmentProgramAction,
): TreatmentProgramsState => {
  switch (action.type) {
    case "SET_ALL_TREATMENT_PROGRAMS":
      return {
        ...state,
        all: action.data.data.filter(tp => tp.category_id !== "archive") || {},
        categories: action.data.meta.categories.filter(
          c => c.category_id !== "archive",
        ),
      };
    case "GET_CLIENT_TREATMENT_PROGRAMS":
      return {
        ...state,
        client_programs: action.data.map(program => ({
          ...program,
          ...parseDates(program),
        })),
      };
    case "UPDATE_ALL_TREATMENT_PROGRAMS": {
      return {
        ...state,
        client_programs: action.data.data.map(
          (program: ClientTreatmentProgram) => ({
            ...program,
            ...parseDates(program),
          }),
        ),
      };
    }
    case "SET_ALL_SITE_TREATMENT_PROGRAMS":
      return {
        ...state,
        all_site_programs: action.data,
      };
    case "SET_TREATMENT_PROGRAM_STUBS":
      return {
        ...state,
        stubs: action.data,
      };
    case "GET_TASK_ACTIONS":
      return {
        ...state,
        allTaskActions: action.data,
      };
    case "CREATE_TASK":
      return {
        ...state,
      };
    case "UPDATE_TASK":
      return {
        ...state,
        client_programs: updateTaskReducer(state, action.data),
      };
    case "DELETE_TASK":
      return {
        ...state,
        client_programs: deleteTaskReducer(state, action.data),
      };
    case "GET_TREATMENT_PROGRAM":
      return {
        ...state,
        treatmentProgram: action.data,
      };
    case "TREATMENT_PROGRAM_ADD_STAFF":
      return {
        ...state,
        treatmentProgram: addTreatmentProgramStaffReducer(state, action.data),
      };
    case "TREATMENT_PROGRAM_ADD_TEAM":
      return {
        ...state,
        treatmentProgram: addTreatmentProgramTeamReducer(state, action.data),
      };
    case "TREATMENT_PROGRAM_REMOVE_STAFF":
      return {
        ...state,
        treatmentProgram: removeTreatmentProgramStaffReducer(
          state,
          action.staffId,
        ),
      };
    case "TREATMENT_PROGRAM_REMOVE_TEAM":
      return {
        ...state,
        treatmentProgram: removeTreatmentProgramTeamReducer(
          state,
          action.teamId,
        ),
      };
    default:
      return state;
  }
};

export const selectTreatmentProgramStubs = (state: RootState) =>
  state.treatmentPrograms.stubs;
